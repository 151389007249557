<template>
    <div :class="isReport ? 'divi' : 'resultBox'">
        <div class="risserResultRow" v-if="record.prev">
            <div class="risserBox">
                <div class="imgBox">
                    <div class="imgW taC" :style="isSmallImageStyle">
                        <img v-if="record.firstSpine && record.firstSpine.risserImgUrl" :src="record.firstSpine.risserImgUrl" alt="척추 엑스레이 이미지" @error="onErrorImage($event)">
                    </div>
                    <p class="date taC">{{ record.firstSpine.pictureDtStr }} (최초 분석)</p>
                </div>
                <ul :class="[`txtList`, isFirstRecord ? `firstTextList` : ``]">
                    <li>
                        <div class="em">
                            <p class="leftTxt">{{ READING_RISSER_NAME }}</p>
                            <p class="rightTxt">{{ record.firstSpine.risserGrade }}</p>
                        </div>
                    </li>
                    <li>
                        <div class="em">
                            <p class="leftTxt">실제 연령</p>
                            <p class="rightTxt">{{ record.firstSpine.atTimeAge }}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="risserBox">
                <div class="imgBox">
                    <div class="imgW taC">
                        <img v-if="record.prev && record.prev.risserImgUrl" :src="record.prev.risserImgUrl" alt="척추 엑스레이 이미지" @error="onErrorImage($event)">
                    </div>
                    <p class="date current taC">{{ record.prev?.pictureDtStr || record.firstSpine?.pictureDtStr || '' }} (직전 분석)</p>
                </div>
                <ul :class="[`txtList`, isFirstRecord ? `firstTextList` : ``]">
                    <li>
                        <div class="em">
                            <p class="leftTxt">{{ READING_RISSER_NAME }}</p>
                            <p class="rightTxt">{{ record.prev?.risserGrade || record.firstSpine?.risserGrade }}</p>
                        </div>
                    </li>
                    <li>
                        <div class="em">
                            <p class="leftTxt">실제 연령</p>
                            <p class="rightTxt">{{ record.prev?.atTimeAge || record.firstSpine?.atTimeAge }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="risserResult firstBox" v-else>
            <div class="risserBox">
                <div class="imgW taC" :style="isSmallImageStyle">
                    <img v-if="record.firstSpine && record.firstSpine.risserImgUrl" :src="record.firstSpine.risserImgUrl" alt="척추 엑스레이 이미지" @error="onErrorImage($event)">
                </div>
                <p class="date current taC">{{ record.firstSpine.pictureDtStr }} (최초 분석)</p>
            </div>
            <ul  :class="[`txtList`, isFirstRecord ? `firstTextList` : ``]">
                <li>
                    <div class="em">
                        <p class="leftTxt">{{ READING_RISSER_NAME }}</p>
                        <p class="rightTxt">{{ record.firstSpine.risserGrade }}</p>
                    </div>
                </li>
                <li>
                    <div class="em">
                        <p class="leftTxt">실제 연령</p>
                        <p class="rightTxt">{{ record.firstSpine.atTimeAge }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>    
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            default: () => ({})
        },
        isFirstRecord: {
            type: Boolean,
            default: true
        },
        isReport: {
            type: Boolean,
            default: false
        },
        patient: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        isSmallImageStyle() {
            // 레포트 모드이고, 이전 분석이 없는 경우에만 이미지 크기를 80%로 조정
            return this.isReport && !this.record.prev ? { width: '80%', margin: '0 auto' } : {}; 
        }
    },
    methods: {
        onErrorImage(event) {
            // 이미지 에러 핸들링 로직
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";

.resultBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.contentBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.imgRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.analysisBox {
    width: 48%;
}

.singleAnalysis {
    width: 100%; /* 추가: 단일 분석 시 전체 너비 사용 */
}

.imgBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.imgBox .imgW {
    width: 100%;
    height: 300px; /* Set a fixed height for the image container */
    background: #444;
    position: relative;
}

.imgBox .imgW img {
    width: 100%;
    height: 100%; /* Make sure the image takes up the full height of the container */
    display: block;
    object-fit: contain; /* Maintain the image's aspect ratio and ensure it fits within the container */
}


.resultInfo {
    display: flex;
    flex-direction: column;
    gap: 5px; /* 간격을 줄여 텍스트를 이미지에 더 가깝게 배치 */
    background: #f9f9f9;
    padding: 5px; /* 패딩을 줄여 텍스트를 이미지에 더 가깝게 배치 */
    border-radius: 4px;
    width: 100%;
    margin-top: 5px; /* 간격을 줄여 텍스트를 이미지에 더 가깝게 배치 */
}

.resultInfo .leftTxt {
    font-weight: bold;
}

.resultInfo .rightTxt {
    text-align: right;
}

.date {
    text-align: center;
    margin-top: 5px;
    font-size: 1.2rem;
}

.resultBox .date{margin-bottom: 16px;}
.resultBox{display: flex; align-items: center; justify-content: space-between; gap: 16px;}
.resultBox .risserResult{width: 100%;}
.resultBox .risserResult .imgBox{width: 100% !important; }
.resultBox .risserResult .imgW{width: 100%; height: auto; padding-top: 140px;}
.resultBox .risserResult .imgW img{height: 100%; width: auto;}
.resultBox .risserResult .txtList{width: 100% !important;}

.risserResultRow{display: flex; align-items: center; justify-content: space-between; gap: 16px; width: 100%;}
.risserResultRow .risserBox{width: calc(100% - 16px)}
.resultBox .risserResultRow .risserBox .imgBox{width: 100% !important; }
.resultBox .risserResultRow .imgW{width: 100%; height: auto; padding-top: 140px;}
.resultBox .risserResultRow .imgW img{height: 100%; width: auto;}
.resultBox .risserResultRow .txtList{width: 100% !important;}

.divi .risserResult{width: 100%;}
.divi .risserResult .imgBox{width: 100% !important; }
.divi .risserResult .imgW{width: 100%; height: auto;}
.divi .risserResult .imgW img{height: 100%; width: auto;}
.divi .risserResult .txtList{width: 100% !important;}

.divi .risserResultRow .risserBox .imgBox{width: 100% !important; }
.divi .risserResultRow .imgW{width: 100%; height: auto;}
.divi .risserResultRow .imgW img{height: 100%; width: auto;}
.divi .risserResultRow .txtList{width: 100% !important;}

</style>
